@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

:root{
  --text-color: rgb(240, 240, 240);
  --text-color-alt: #afb6cd;
  --text-color-dark: #666666;
  --primary-background-color: #1a1a1a;
  --button-background-color: #666666;
  --button-border-color: #464646;
  --secondary-background-color: #252830;
  --hover-text-color: var(--text-color);
  --hover-dark-color: var(--primary-background-color);
  --gradient-color: linear-gradient(
      115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
  --myShadow: 0 1px 5px 0 rgba(179, 179, 179, 0.884),0 10px 10px 0 rgba(0,0,0,0.19);
  --transparent-text-color: rgba(255,255,255,.05);
  --transparent-dark-color: rgba(0, 0, 0, 0.25);
  --font-family: 'Poppins', sans-serif;
  --font-size-xxsm: 0.7rem;
  --font-size-xsm: 1rem;
  --font-size-sm: 1.5rem;
  --font-size-md: 2rem;
  --font-size-lg: 2.5rem;
  --font-size-xl: 3rem;
  --gap: 1rem;
  --margin-sm: 2rem;
  --margin-md: 3rem;
  --item-min-height-sm: 20rem;
  --item-min-height-md: 30rem;
  --font-roboto: "Roboto Slab", serif;
  --font-raleway: "Raleway", sans-serif;

  --imageWidth: 190px;
  --newsWidth: 400px;
  --newsImageWidth: 380px;


}

#light {
  --text-color: rgb(240, 240, 240);
  --text-color-alt: #afb6cd;
  --primary-background-color: #1a1a1a;
  --button-background-color: #2c2c2c;
  --button-border-color: #424242;
  --secondary-background-color: #252830;
  --hover-text-color: var(--text-color);
  --hover-dark-color: var(--primary-background-color);
  --gradient-color: linear-gradient(
      115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
  --myShadow: 0 1px 5px 0 rgba(179, 179, 179, 0.884),0 10px 10px 0 rgba(0,0,0,0.19);
  --transparent-text-color: rgba(255,255,255,.05);
  --transparent-dark-color: rgba(0,0,0,.25);
  --font-family: 'Poppins', sans-serif;

  --gap: 1rem;
  --margin-sm: 2rem;
  --margin-md: 3rem;
  --item-min-height-sm: 20rem;
  --item-min-height-md: 30rem;
  --font-roboto: "Roboto Slab", serif;
  --font-raleway: "Raleway", sans-serif;
}
#dark {
  --text-color: rgb(24, 24, 24);
  --text-color-alt: #202020;
  --primary-background-color: #e4e4e4;
  --button-background-color: #b9b9b9;
  --button-border-color: #c5c5c5;
  --secondary-background-color: #858585;
  --hover-text-color: var(--text-color);
  --hover-dark-color: var(--primary-background-color);
  --gradient-color: linear-gradient(
      115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
  --myShadow: 0 1px 5px 0 rgba(36, 36, 36, 0.884),0 10px 10px 0 rgba(199, 199, 199, 0.19);
  --transparent-text-color: rgba(201, 201, 201, 0.05);
  --transparent-dark-color: rgba(189, 189, 189, 0.25);
  --font-family: 'Poppins', sans-serif;
  --gap: 1rem;
  --margin-sm: 2rem;
  --margin-md: 3rem;
  --item-min-height-sm: 20rem;
  --item-min-height-md: 30rem;
  --font-roboto: "Roboto Slab", serif;
  --font-raleway: "Raleway", sans-serif;
}


* {
  font-size: 15px; 
  margin: 0;
  border: 0;
  box-sizing: border-box;
  background-color: var(--primary-background-color);
}

body {
  font-family: var(--font-roboto);
  background-color: var(--primary-background-color);
}

.app {
  padding: 0.4rem;
  display:flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  background-color: var(--primary-background-color);
}
.bg {
  background-color: var(--primary-background-color);
  height: 1000px;
}
h1 {
  font-size: 3rem;
  letter-spacing: 0.9px;
  color: var(--text-color);
  width: fit-content;
}

.empty h2 {
  font-size: 1.25rem;
  color: var(--text-color);
  font-family: var(--font-raleway);
}



.navBar {
  background-color: var(--primary-background-color);
  color: var(--text-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding:  0.1rem;
  border-radius: 5px;
  border: 2px var(--text-color);
  border-style: groove;
  position: relative;
}


.navBarHeader {
  font-size: 3rem;
  margin-left: 1rem;
  color: var(--text-color);
  background-color: var(--primary-background-color);
}
.navBar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  position: relative;
}
#CLink {
  font-size: 1.2rem;
}
#CLink:hover {
  background-color:  var(--secondary-background-color);
}

.navBar a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.navBar li.active {
  border: 1px var(--text-color-alt);
  border-style: groove;
}
.navBarButtons{
  width: 100%;
  justify-content: center;
 
}





.footer{
  color: var(--text-color);
  position: relative;
  left: 0;
  width: 100%;
}
.footerHR{
    color: var(--text-color);
    background-color: var(--text-color);
    height: 1.5px;
}

.footer_list{
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}
.footer-small {
  text-align: center;
}
.footer-row{
  display: flex;
  
}
.footer-col {
  flex:1;
  margin: 0.5rem;
  margin-left: 2rem;
  text-align: left;
  
  margin-bottom: 0.5rem;
}


.about-div{
  border-radius: 12px;
  margin: 2rem;
  padding: 1rem;
  background-color: var(--primary-background-color);
  color: var(--text-color);
}

.about-list{
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.about-list-header{
  text-align: center;
}




.container {
  width: 100%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 12px;
  border: 2px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);
}

.pageContainer {
  margin-top: 1rem;
  text-align: center;
  font-size: var(--font-size-md);
  color: var(--text-color);
  background-color: var(--primary-background-color);
}

.itemsHeader {
  font-size: var(--font-size-sm);
  padding: 0;
}


/* News */
.news
{ 
  width: 100vh;
  height: fit-content;
  padding-top: 1rem;
  margin-bottom: 1rem;
  background-color: var(--primary-background-color);
  position: relative;
  border-radius: 12px;
  overflow:hidden;
  flex-direction: row;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  border: 2px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);
  font-size: 1rem;
}

.newsImage{
  width: var(--newsImageWidth);
  border-radius: 12px;
  margin:0.2rem;
  border: 2px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);
  
}
.newsDate{
  text-decoration: underline;
}

/* Products */
.item {
  width: 200px;
  height: 350px;
  margin: .5rem;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--primart-background-color);
  border: 2px var(--text-color-dark);
  border-style: ridge;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  border: 1px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);
}

/*
.item:hover {
  transform: scale(2, 2);
  z-index: 2;
}
*/

.itemImage {
  margin:auto;
  height: var(--imageWidth);
  width: var(--imageWidth);
  border-radius: 12px;
  background-color: var(--primary-background-color);
  margin-bottom: 2rem;
  
  
}
.itemImage {
  margin:0;
  
  border-radius: 50%;
  background-color: var(--primary-background-color);
  border: 1px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);
}
.itemTitle {
  font-weight: 600;
  font-size: var(--font-size-xsm);
}
.itemDate {
  margin: 0;
  font-weight: 1000;
  font-size: var(--font-size-xxsm);
}
.itemComment {
  font-weight: 600;
  font-size:var(--font-size-xxsm);
  margin:0;
  padding:0;
  height:fit-content;

}


.how{

  height: fit-content;
  width: 100vh;
  background-color: var(--primart-background-color);
  border-radius: 12px;
  border: 2px var(--text-color-dark);
  border-style: groove;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  margin-bottom: 1rem;
  position: relative;
  overflow:hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);
  font-size: 1rem;


}
.howTitle {
  margin: 0.5rem;
  font-weight: 600;
  font-size: var(--font-size-xsm);
  
}

.howComment{
  margin: 1rem;  
}
.itemImageHow {
  width: var(--newsImageWidth);
  margin: 0rem;
  border: 1px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);
  border-radius: 12px;
  background-color: var(--primary-background-color);
  
}


.themeSwitch {
  height: 100%;
  background-color: transparent;

  position: absolute;
  right:0;
  top:20%;
 
}


.video-responsive {
  position: absolute;
  height: 580px;
 
  overflow:hidden;
  margin: rem;
  padding-bottom: 30%;
  position: relative;  
}

.video-responsive iframe {
  margin: 0rem;
  transform: translate(-50%, 0%);
  width: fit-content;
  height: 550px;
  position: absolute;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  border-radius: 12px;
  border: 1px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);

  
}
.about-div {
  border: 2px var(--text-color);
  border-style: groove;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.7);

}

.myButton {
  margin: 1rem;
  font-family: var(--font-roboto);
  font-weight: 800;
  color: var(--text-color);
  font-size: var(--font-size-xsm);
  background-color: var(--button-background-color);
  border: 3px solid var(--button-border-color);
  border-radius: 4px;
  border-style:groove;
  width: fit-content;
  height: min-content;
}

@media screen and (max-width: 600px) {
  .app {
    padding: 4rem 2rem;
  }

  .app {
    padding: 1rem 1.75rem;
    width: 100%;
  }

  .search input {
    font-size: 1rem;
  }

  .search img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 400px) {
  .app {
    padding: 2rem 1rem;
  }
  #CLink {
    font-weight: 700;
    font-size: 0.8rem;
  }
  .navBar ul {
    gap: 0.5rem;
  }
  :root{
    --newsWidth: 375px;
    --newsImageWidth: 350px;

  }
  .newsImage{
    width: var(--newsImageWidth);
    border-radius: 12px;
    margin: 0.2rem;

    
  }
  .themeSwitch {
    padding: 0rem;
    position: absolute;
    right:0;
    top: 0%;
   
  }

  h1 {
    font-size: 1rem;
  }

  .container {
    margin-top: 1rem;
  }

  .item {
    width: "100%";
    margin: 0.1rem;
  }
  .navBarHeader {
    font-size: 1rem;
    margin-left: 0.1rem;
    color: var(--text-color);
    background-color: var(--primary-background-color);
  }
}
